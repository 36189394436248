import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const GtmPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityGtmPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const gtmPageProps = {
    id: data.sanityGtmPage._id,
    metadata: { ...data.sanityGtmPage._rawRoute, href },
    sections: data.sanityGtmPage._rawSections,
    bubbleCtaSection: data.sanityGtmPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      locale={locale}
      menuColor="white"
      sanityId={gtmPageProps.id}
      {...gtmPageProps.metadata}
    >
      {renderPageSections(gtmPageProps.sections)}
      {gtmPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...gtmPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

GtmPage.propTypes = {
  data: PropTypes.shape({ sanityGtmPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

GtmPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default GtmPage;

export const query = graphql`
  query($slug: String) {
    sanityGtmPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      route {
        routeName: _rawRouteName(resolveReferences: { maxDepth: 100 })
        metaTitle: _rawMetaTitle(resolveReferences: { maxDepth: 100 })
        metaDescription: _rawMetaDescription(
          resolveReferences: { maxDepth: 100 }
        )
        metaKeywords: _rawMetaKeywords(resolveReferences: { maxDepth: 100 })
      }
      _id
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
