import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { pageDimensions, unitConverter as uc } from '../../../../styles/base';
import squigglesPng from '../../../../../static/squiggles.png';

const Squiggle = ({ className, marginBottom, marginTop }) => {
  const squiggleCss = css`
    width: ${pageDimensions.desktopWidth};
    max-width: 100%;
    height: ${uc('6px')};
    margin: ${marginTop} auto ${marginBottom};
    background: url(${squigglesPng});
    background-repeat: no-repeat;
  `;
  return <div css={squiggleCss} className={`squiggle-d7 ${className}`} />;
};

/**
 * Prop types
 */
Squiggle.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Default props
 */
Squiggle.defaultProps = {
  marginTop: `${uc('30px')}`,
  marginBottom: `${uc('30px')}`,
  className: '',
};

export default Squiggle;
